var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-header" }),
    _c(
      "div",
      { staticClass: "page-body" },
      [
        _vm.current == 1
          ? _c("div", { staticClass: "post-content" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.curDetail.content) }
              })
            ])
          : _vm._e(),
        _c("post-table", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current == 0,
              expression: "current == 0"
            }
          ],
          on: { change: _vm.showComment }
        }),
        _vm.current == 1
          ? _c("comment-table", {
              attrs: { detail: _vm.curDetail },
              on: { change: _vm.showPost }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "page-bottom" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }