<template>
  <div class="page">
    <div class="page-header"></div>
    <div class="page-body">
      <div class="post-content" v-if="current == 1">
        <!-- <div style="text-align:center">帖子预览</div> -->
        <div v-html="curDetail.content"></div>
      </div>
      <post-table v-show="current == 0" @change="showComment"></post-table>
      <comment-table v-if="current == 1" @change="showPost" :detail="curDetail"></comment-table>
    </div>
    <div class="page-bottom">

    </div>
  </div>
</template>

<script>
import postTable from "@/views/cmsPage/dqMannage/bbs/postManage/postTable"
import commentTable from "@/views/cmsPage/dqMannage/bbs/postManage/commentTable"
export default {
  data(){
    return {
      current: 0,
      curDetail: ""
    }
  },
  components:{
    postTable,
    commentTable
  },
  methods:{
    // 显示评论
    showComment(row){
      this.curDetail = JSON.parse(JSON.stringify(row))
      this.current = 1
    },
    // 显示帖子列表
    showPost(){
      this.current = 0
    },
  }
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.post-content{
  padding: 0 300px;
  max-height: 300px;
  overflow-y: scroll;
}
</style>