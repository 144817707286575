<template>
  <div>
    <div class="header">
      <a-input
        size="small"
        v-model="searchParams.title"
        placeholder="标题"
        class="header-item width"
        allowClear
      />
      <!-- @pressEnter="search"
        @change="valueNull" -->
      <!-- 置顶 -->
      <a-select
        class="header-item width"
        size="small"
        v-model="searchParams.topStatus"
        placeholder="置顶"
        allowClear
      >
        <a-select-option
          v-for="item of topStatus"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <!-- 推荐 -->
      <a-select
        class="header-item width"
        size="small"
        v-model="searchParams.recommendStatus"
        placeholder="推荐"
        allowClear
      >
        <a-select-option
          v-for="item of recommendStatus"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button @click="search" type="primary" size="small" class="header-item"
        >搜索</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="changePage"
      rowKey="id"
    >
      <!-- 置顶 -->
      <span slot="top" slot-scope="val">
        <span :style="'color: ' + (val ? 'green' : '')">{{
          val ? "是" : "否"
        }}</span>
      </span>

      <!-- 推荐 -->
      <span slot="recommend" slot-scope="val">
        <span :style="'color: ' + (val ? 'green' : '')">{{
          val ? "是" : "否"
        }}</span>
      </span>

      <!-- 推荐 -->
      <span slot="visible" slot-scope="val">
        <span :style="'color: ' + (val ? 'green' : 'red')">{{
          val ? "全部" : "自己"
        }}</span>
      </span>

      <!-- 操作 -->
      <span slot="action" slot-scope="row">
        <a class="action-item" @click="showComment(row)">评论</a>
        <a class="action-item" @click="recommendClick(row)">{{
          row.recommendStatus == 1 ? "取消推荐" : "推荐"
        }}</a>
        <a class="action-item" @click="toppingClick(row)">{{
          row.toppingStatus == 1 ? "取消置顶" : "置顶"
        }}</a>
        <a class="action-item" @click="visibleClick(row)">{{
          row.openStatus == 1 ? "自己可见" : "全部可见"
        }}</a>
        <a class="action-item" @click="deleteClick(row)">删除</a>
      </span>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "回复数",
    dataIndex: "commentCount",
    key: "commentCount",
  },
  {
    title: "点击数",
    dataIndex: "clickNum",
    key: "clickNum",
  },
  {
    title: "置顶",
    dataIndex: "toppingStatus",
    key: "toppingStatus",
    scopedSlots: { customRender: "top" },
  },
  {
    title: "推荐",
    dataIndex: "recommendStatus",
    key: "recommendStatus",
    scopedSlots: { customRender: "recommend" },
  },
  {
    title: "可见",
    dataIndex: "openStatus",
    key: "openStatus",
    scopedSlots: { customRender: "visible" },
  },
  {
    title: "发帖时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      list: [],
      columns,
      searchParams: {
        pageNum: 1,
        pageSize: 10,
        title: "",
      },
      topStatus: [
        { name: "是", value: 1 },
        { name: "否", value: 0 },
      ],
      recommendStatus: [
        { name: "是", value: 1 },
        { name: "否", value: 0 },
      ],
      openStatus: [
        { name: "全部", value: 1 },
        { name: "自己", value: 0 },
      ],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
    };
  },
  mounted() {
    this.getPostList();
  },
  methods: {
    // 删除
    deleteClick(row) {
      const { id } = row;
      this.$confirm({
        title: "确定要删除该帖子吗?",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.axios("/dq_admin/bbsPost/delById", {
            params: {
              id
            },
          });
          if (res.status != 200) return;
          this.$message.success("删除成功");
          this.getPostList();
        },
        onCancel: function onCancel() {},
      });
    },
    // 置顶
    toppingClick(row) {
      const { id, toppingStatus } = row;
      this.$confirm({
        title: toppingStatus == 1 ? "取消该帖子置顶" : "将该帖子设置为置顶",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.axios("/dq_admin/bbsPost/topChange", {
            params: {
              id,
              topStatus: toppingStatus == 1 ? 0 : 1,
            },
          });
          if (res.status != 200) return;
          this.$message.success("操作成功");
          this.getPostList();
        },
        onCancel: function onCancel() {},
      });
    },
    // 推荐
    recommendClick(row) {
      const { id, recommendStatus } = row;
      this.$confirm({
        title: recommendStatus == 1 ? "取消该帖子推荐" : "将该帖子设置为推荐",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.axios("/dq_admin/bbsPost/recommendChange", {
            params: {
              id,
              recommendStatus: recommendStatus == 1 ? 0 : 1,
            },
          });
          if (res.status != 200) return;
          this.$message.success("操作成功");
          this.getPostList();
        },
        onCancel: function onCancel() {},
      });
    },
    //可见修改
    visibleClick(row) {
      const { id, openStatus } = row;
      this.$confirm({
        title: openStatus == 1 ? "设置自己可见" : "设置全部可见",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.axios("/dq_admin/bbsPost/visibleChange", {
            params: {
              id,
              openStatus: openStatus == 1 ? 0 : 1,
            },
          });
          if (res.status != 200) return;
          this.$message.success("操作成功");
          this.getPostList();
        },
        onCancel: function onCancel() {},
      });
    },

    // 搜索
    search() {
      this.searchParams.pageNum = 1
      this.getPostList()
    },
    // 帖子列表
    async getPostList() {
      const res = await this.axios("/dq_admin/bbsPost/list", {
        params: {
          ...this.searchParams,
        },
      });
      if (res.status != 200) return;
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.searchParams, "pageNum", current);
      this.getPostList();
    },
    // 查看评论
    showComment(row) {
      this.$emit("change", row);
    },
  },
};
</script>
<style lang="scss" scoped>
.header-item {
  margin: 0 10px 10px 0;
  &.width {
    width: 150px;
  }
}
.action-item {
  margin-right: 8px;
}
</style>